import React from 'react';
import { useAuth } from '../authentication/AuthContext';
import '../styles/Footer.css';

function Footer() {
  const { firstName } = useAuth();

  return (
    <footer className="footer">
        {firstName && <p style={{margin: '5px'}}>Welcome, {firstName}!</p>}
        <nav>
            <a href="/legal/privacy_policy.html">Privacy Policy</a>
        </nav>
        <nav>
          <a href="/legal/terms_of_service.html">Terms of Service</a>
        </nav>
        <p>&copy; 2024 InstantFlows. All rights reserved.</p>
    </footer>
  );
}

export default Footer;