// client/src/components/Research/PersonaView.js
import React, { useState } from 'react';
import editPencil from '../components/icons/edit_pencil.png';
import trashBin from '../components/icons/trash_bin.png';
import { defaultPersonaFilters } from './ResearchConstants';
import { validateFilters } from './ResearchActions';
import '../styles/ResearchPage.css';
import '../index.css';

export const PersonaFilters = ({ onApplyFilters }) => {
  const [filters, setFilters] = useState(defaultPersonaFilters);

  const handleSubmit = (e) => {
    e.preventDefault();
    onApplyFilters(validateFilters(filters));
  };

  return (
    <div className="research-filters">
      <h4>Define Your Target Demographic</h4>
      <form onSubmit={handleSubmit}>
        <div className="filter-group">
          <label>Age Range:</label>
          <div className="age-range">
            <input
              type="number"
              value={filters.ageRange.min}
              onChange={(e) => setFilters({
                ...filters,
                ageRange: { ...filters.ageRange, min: parseInt(e.target.value) }
              })}
              min="18"
              max="100"
            />
            <span>to</span>
            <input
              type="number"
              value={filters.ageRange.max}
              onChange={(e) => setFilters({
                ...filters,
                ageRange: { ...filters.ageRange, max: parseInt(e.target.value) }
              })}
              min="18"
              max="100"
            />
          </div>
        </div>

        <div className="filter-group">
          <label>Region:</label>
          <select
            value={filters.region}
            onChange={(e) => setFilters({ ...filters, region: e.target.value })}
            style={{ width: '100%' }} // Ensures the select box width is consistent across different window sizes
          >
            <option value="Any">Any Country</option>
            <option value="United States">United States</option>
            <option value="Netherlands">Netherlands</option>
          </select>
        </div>


        <div className="filter-group">
          <label>Income Range (€/year):</label>
          <div className="age-range">
            <input
              type="number"
              value={filters.incomeRange.min}
              onChange={(e) => setFilters({
                ...filters,
                incomeRange: { ...filters.incomeRange, min: parseInt(e.target.value) }
              })}
              min="0"
              max="1000000"
            />
            <span>to</span>
            <input
              type="number"
              value={filters.incomeRange.max}
              onChange={(e) => setFilters({
                ...filters,
                incomeRange: { ...filters.incomeRange, max: parseInt(e.target.value) }
              })}
              min="0"
              max="1000000"
            />
          </div>
        </div>
      
        <div className="filter-group" style={{ width: '100%'}}>
            <label>Number of People: <span>{filters.count}</span></label>
            <input
              type="range"
              value={filters.count}
              onChange={(e) => setFilters({ ...filters, count: parseInt(e.target.value) })}
              min="1"
              max="100"
            />
          </div>
        <div style={{justifyContent: 'center'}}>   
          <button type="submit" className="button research-button" style={{ width: '95%' }}>
          Make Personas
        </button>
        </div>
     
      </form>
    </div>
  );
};

export const Persona = ({ persona, onEdit, onDelete, viewType }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [personaData, setPersonaData] = useState(persona);

  const handleSave = () => {
    onEdit(persona.id, personaData);
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  if (viewType === 'list') {
    return (
      <div className="research-list-item">
        <div className="research-list-content">
          <img src="/InstantFlowsicon_wb.png" alt="Profile" className="list-profile-pic" />
          <div className="list-item">{isEditing ? (
            <input
              value={personaData.name}
              onChange={(e) => setPersonaData({ ...personaData, name: e.target.value })}
              placeholder="Name"
              onKeyDown={handleKeyDown}
            />
          ) : persona.name}</div>
          <div className="list-item">{isEditing ? (
            <input
              value={personaData.age}
              onChange={(e) => setPersonaData({ ...personaData, age: e.target.value })}
              placeholder="Age"
              onKeyDown={handleKeyDown}
            />
          ) : persona.age}</div>
          <div className="list-item">{isEditing ? (
            <input
              value={personaData.location}
              onChange={(e) => setPersonaData({ ...personaData, location: e.target.value })}
              placeholder="Location"
              onKeyDown={handleKeyDown}
            />
          ) : persona.location}</div>
          <div className="list-item">{isEditing ? (
            <input
              value={personaData.income}
              onChange={(e) => setPersonaData({ ...personaData, income: e.target.value })}
              placeholder="Income"
              onKeyDown={handleKeyDown}
            />
          ) : persona.income}</div>
          <div className="list-item">{isEditing ? (
            <input
              value={personaData.bio}
              onChange={(e) => setPersonaData({ ...personaData, bio: e.target.value })}
              placeholder="Bio"
              onKeyDown={handleKeyDown}
            />
          ) : persona.bio}</div>
          <div className="list-actions">
            {isEditing ? (
              <button className='button' onClick={handleSave}>Save</button>
            ) : (
              <>
                <img src={editPencil} alt="Edit" onClick={() => setIsEditing(true)} className="click-icon" />
                <img src={trashBin} alt="Delete" onClick={() => onDelete(persona.id)} className="click-icon" />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="research-grid-item">
      {isEditing ? (
        <div className="research-edit-form">
          <input
            value={personaData.name}
            onChange={(e) => setPersonaData({ ...personaData, name: e.target.value })}
            placeholder="Name"
            onKeyDown={handleKeyDown}
          />
          <input
            value={personaData.age}
            onChange={(e) => setPersonaData({ ...personaData, age: e.target.value })}
            placeholder="Age"
            onKeyDown={handleKeyDown}
          />
          <input
            value={personaData.location}
            onChange={(e) => setPersonaData({ ...personaData, location: e.target.value })}
            placeholder="Location"
            onKeyDown={handleKeyDown}
          />
          <input
            value={personaData.income}
            onChange={(e) => setPersonaData({ ...personaData, income: e.target.value })}
            placeholder="Income"
            onKeyDown={handleKeyDown}
          />
          <textarea
            value={personaData.bio}
            onChange={(e) => setPersonaData({ ...personaData, bio: e.target.value })}
            placeholder="Bio"
            onKeyDown={handleKeyDown}
          />
          <button className='button' onClick={handleSave}>Save</button>
        </div>
      ) : (
        <div className="grid-content">
          <div className="profile-pic">
            <img src="/InstantFlowsicon_wb.png" alt="Profile" />
          </div>
          <h4>{persona.name}</h4>
          <div className="grid-details">
            <p>Age: {persona.age}</p>
            <p>Location: {persona.location}</p>
            <p>Income: {persona.income}</p>
            <p>Bio: {persona.bio}</p>
          </div>
          <div className="grid-actions">
            <img src={editPencil} alt="Edit" onClick={() => setIsEditing(true)} className="click-icon" />
            <img src={trashBin} alt="Delete" onClick={() => onDelete(persona.id)} className="click-icon" />
          </div>
        </div>
      )}
    </div>
  );
};
export default Persona;