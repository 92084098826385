import '../styles/ResearchPage.css';
import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../authentication/AuthContext';

import TabContent from './TabContent';
import { 
  topicActions, questionActions, personaActions, loadResearchData, saveToStorage
} from './ResearchActions';
import trashBin from '../components/icons/trash_bin.png';
import { MAX_VISIBLE_PERSONAS } from './ResearchConstants';

const handleUpgradeToPremium = () => {
  const upgradeConfirmed = window.confirm('To add more than 10 questions, you need to upgrade this research to a premium research. This will cost an additional 2 credits. Do you want to proceed?');
  if (upgradeConfirmed) {
    updateUserAttributes({ 'custom:credits': credits - 2 });
    topicActions.upgradeToPremium(selectedTopic, topics, setTopics, setSelectedTopic);
    onAddQuestion();
  }
};

const handlePersonaUpgrade = () => {
  const upgradeConfirmed = window.confirm('To have more than 100 personas, you need to upgrade this research to a premium research. This will cost an additional 2 credits. Do you want to proceed?');
  if (upgradeConfirmed) {
    updateUserAttributes({ 'custom:credits': credits - 2 });
    topicActions.upgradeToPremium(selectedTopic, topics, setTopics, setSelectedTopic);
    onAddPersona();
  }
};

const handleAIUpgrade = () => {
  const upgradeConfirmed = window.confirm('To analyze with AI, you need to upgrade this research to a premium research. This will cost an additional 2 credits. Do you want to proceed?');
  if (upgradeConfirmed) {
    updateUserAttributes({ 'custom:credits': credits - 2 });
    topicActions.upgradeToPremium(selectedTopic, topics, setTopics, setSelectedTopic);
    // Continue with AI analysis
  }
};

function ResearchPage() {
  const { updateUserAttributes, credits} = useAuth();
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedTab, setSelectedTab] = useState('Questions');
  const [newTopicName, setNewTopicName] = useState('');
  const [personas, setPersonas] = useState([]);
  const [visibleCount, setVisibleCount] = useState(MAX_VISIBLE_PERSONAS);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isPremiumResearch, setIsPremiumResearch] = useState(false);
  const [researchDescription, setResearchDescription] = useState('');
  const [mainResearchQuestion, setMainResearchQuestion] = useState('');
  const [researchType, setResearchType] = useState('BASIC');
  const topicInputRef = useRef(null);

  // Load research data on component mount
  useEffect(() => {
    const savedTopics = loadResearchData();
    setTopics(savedTopics);
  }, []);

  const handleNewResearchClick = () => {
    topicActions.newResearch(setSelectedTopic, setSelectedTab);
    topicInputRef.current?.focus();
    setIsSidebarVisible(false);
  };

  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
    setSelectedTab('Questions');
    setPersonas(topic.personas || []);
    setVisibleCount(MAX_VISIBLE_PERSONAS);
    setIsSidebarVisible(false);
    setIsPremiumResearch(topic.isPremium);
  };

  const handleNextTab = () => {
    if (selectedTab === 'Questions') setSelectedTab('Personas');
    else if (selectedTab === 'Personas') setSelectedTab('Responses');
    setIsSidebarVisible(false);
  };

  const handleUpgradeToPremium = () => {
    const upgradeConfirmed = window.confirm('To add more than 10 questions, you need to upgrade this research to a premium research. This will cost an additional 2 credits. Do you want to proceed?');
    if (upgradeConfirmed) {
      updateUserAttributes({ 'custom:credits': credits - 2 });
      
      // Update topics and save
      const updatedTopics = topics.map(t => 
        t.id === selectedTopic.id ? { ...t, isPremium: true } : t
      );
      setTopics(updatedTopics);
      setSelectedTopic(prev => ({ ...prev, isPremium: true }));
      saveToStorage(updatedTopics);
      setIsPremiumResearch(true);
      
      // Continue with adding question
      questionActions.addQuestion(selectedTopic, setSelectedTopic, topics, setTopics);
    }
  };

  const handlePersonaUpgrade = () => {
    const upgradeConfirmed = window.confirm('To have more than 100 personas, you need to upgrade this research to a premium research. This will cost an additional 2 credits. Do you want to proceed?');
    if (upgradeConfirmed) {
      updateUserAttributes({ 'custom:credits': credits - 2 });
      
      // Update topics and save
      const updatedTopics = topics.map(t => 
        t.id === selectedTopic.id ? { ...t, isPremium: true } : t
      );
      setTopics(updatedTopics);
      setSelectedTopic(prev => ({ ...prev, isPremium: true }));
      saveToStorage(updatedTopics);
      setIsPremiumResearch(true);
      
      // Continue with adding persona
      personaActions.addPersona(personas, setPersonas, selectedTopic, setTopics, topics);
    }
  };

  const handleAIUpgrade = () => {
    const upgradeConfirmed = window.confirm('To analyze with AI, you need to upgrade this research to a premium research. This will cost an additional 2 credits. Do you want to proceed?');
    if (upgradeConfirmed) {
      updateUserAttributes({ 'custom:credits': credits - 2 });
      
      // Update topics and save  
      const updatedTopics = topics.map(t => 
        t.id === selectedTopic.id ? { ...t, isPremium: true } : t
      );
      setTopics(updatedTopics);
      setSelectedTopic(prev => ({ ...prev, isPremium: true }));
      saveToStorage(updatedTopics);
      setIsPremiumResearch(true);
      
      // Continue with AI analysis
      // ... AI analysis code ...
    }
  };

  const handleAddTopic = () => {
    const creditCost = researchType === 'BASIC' ? 1 : 3;
    if (credits < creditCost) {
      alert('Insufficient credits to start a new research.');
      return;
    }
    updateUserAttributes({ 'custom:credits': credits - creditCost });

    const topicName = `${mainResearchQuestion} - ${researchDescription}`;
    topicActions.addTopic(
      topicName, 
      setTopics, 
      setNewTopicName, 
      setSelectedTopic, 
      setSelectedTab,
      researchType === 'PREMIUM'
    );
    setIsSidebarVisible(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && newTopicName.trim()) {
      handleAddTopic();
    }
  };

  const TopicList = ({ topics, onTopicClick, onTopicDelete }) => (
    <div className="topic-list">
      <ul>
        {topics.map((topic) => (
          <li 
            key={topic.id} 
            onClick={() => onTopicClick(topic)}
            className={topic.isPremium ? 'premium-topic' : ''}
          >
            <span>{topic.name}</span>
            <div className="topic-actions">
              {topic.isPremium && <span className="premium-star">★</span>}
              <img 
                src={trashBin} 
                alt="Delete" 
                onClick={(e) => {
                  e.stopPropagation();
                  onTopicDelete(topic.id);
                }} 
                className="click-icon" 
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div className="research-page">
      <aside className={`sidebar ${isSidebarVisible ? 'visible' : 'collapsed'}`}>
        <div className="sidebar-header">
          <h2>Your Research Topics</h2>
        </div>
        <button className="button standout-button research-button" style={{ maxWidth: 'none', width: '90%' }} onClick={handleNewResearchClick}>
          New Research
        </button>
        <TopicList
          topics={topics}
          selectedTopic={selectedTopic}
          newTopicName={newTopicName}
          setNewTopicName={setNewTopicName}
          onTopicClick={handleTopicClick}
          onAddTopic={handleAddTopic}
          onTopicDelete={(topicId) => topicActions.deleteTopic(topicId, topics, setTopics, setSelectedTopic)}
        />
      </aside>

      <button 
        className="toggle-sidebar"
        onClick={() => setIsSidebarVisible(!isSidebarVisible)}
      >
        {isSidebarVisible ? '←' : '→'}
      </button>

      <main className={`research-content ${isSidebarVisible ? 'visible' : 'collapsed'}`}>
      {selectedTopic ? (
        <>
        <h2>{selectedTopic.name} - {selectedTopic.isPremium ? 'Premium' : 'Basic'}</h2>
        <div className="tabs">
          {['Questions', 'Personas', 'Responses'].map(tab => (
          <button
          key={tab}
          className={`tab ${selectedTab === tab ? 'active' : ''}`}
          onClick={() => {
            setSelectedTab(tab);
            setIsSidebarVisible(false);
          }}
          >
            {tab}
          </button>
          ))}
        </div>
        <div className="tab-content">
          <TabContent
            selectedTab={selectedTab}
            selectedTopic={selectedTopic}
            onQuestionEdit={(questionId, updates) => 
              questionActions.editQuestion(questionId, updates, selectedTopic, setSelectedTopic, topics, setTopics)}
            onQuestionDelete={(questionId) => 
              questionActions.deleteQuestion(questionId, selectedTopic, setSelectedTopic, topics, setTopics)}
            onAddQuestion={() => {
              if (selectedTopic.questions.length >= 10 && !selectedTopic.isPremium) {
                handleUpgradeToPremium();
              } else {
                questionActions.addQuestion(selectedTopic, setSelectedTopic, topics, setTopics);
              }
            }}
            onAnalyzeWithAI={() => {
              if (!selectedTopic.isPremium) {
                handleAIUpgrade();
              } else {
                // Perform the analysis
                // ... AI analysis code ...
              }
            }}
            personas={personas}
            setPersonas={setPersonas}
            onPersonaEdit={(personaId, updates) => 
              personaActions.editPersona(personaId, updates, personas, setPersonas, selectedTopic, setTopics, topics)}
            onPersonaDelete={(personaId) => 
              personaActions.deletePersona(personaId, personas, setPersonas, selectedTopic, setTopics, topics)}
            onAddPersona={() => {
              if (personas.length > 100 && !selectedTopic.isPremium) {
                handlePersonaUpgrade();
              } else {
                personaActions.addPersona(personas, setPersonas, selectedTopic, setTopics, topics);
              }
            }}
            onGeneratePersonas={(filters) => 
              personaActions.generatePersonas(filters, setPersonas, personas, selectedTopic, setTopics, topics)}
            onNextTab={handleNextTab}
            visibleCount={visibleCount}
            setVisibleCount={setVisibleCount}
            isPremiumResearch={selectedTopic.isPremium}
            topics={topics}
            setTopics={setTopics}
          />
        </div>
        </>
        ) : (
          
          <div className="container new-topic">
            <h3>What would you like to research?</h3>
            <input
              type="text"
              value={mainResearchQuestion}
              onChange={(e) => setMainResearchQuestion(e.target.value)}
              placeholder="Enter new research question"
            />
            <input
              type="text"
              value={researchDescription}
              onChange={(e) => setResearchDescription(e.target.value)}
              placeholder="Add research description"
            />
            <select
              value={researchType}
              onChange={(e) => setResearchType(e.target.value)}
            >
              <option value="BASIC">Basic Research (1 credit)</option>
              <option value="PREMIUM">Premium Research (3 credits)</option>
            </select>
            <button
              className="button research-button"
              onClick={handleAddTopic}
              disabled={!mainResearchQuestion.trim() || !researchDescription.trim()}
            >
              Submit
            </button>
          </div>
        )}

      </main>
      </div>
  );
}

export default ResearchPage;
