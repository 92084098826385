import '../styles/ResearchPage.css';
import React, { useState } from 'react'; // Added useState
import Question from './QuestionView';
import { Persona, PersonaFilters } from './PersonaView';
import ResponsesView from './ResponsesView';
import { personaActions } from './ResearchActions';

const TabContent = ({
  selectedTab,
  selectedTopic,
  onQuestionEdit,
  onQuestionDelete,
  onAddQuestion,
  personas,
  setPersonas,
  onPersonaEdit,
  onPersonaDelete,
  onAddPersona,
  onNextTab,
  visibleCount,
  setVisibleCount,
  topics,
  setTopics
}) => {
  // Add state for view type
  const [viewType, setViewType] = useState('grid');

  switch (selectedTab) {
    case 'Questions':
      return (
        <div className="research-container">
          <h3>Questions Overview</h3>
          <div className="research-grid">
            {selectedTopic.questions.map((question) => (
              <Question
                key={question.id}
                question={question}
                onEdit={onQuestionEdit}
                onDelete={onQuestionDelete}
              />
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button className="button research-button" style={{ width: '50%' }} onClick={onAddQuestion}>Add Question</button>
            <button className="button research-button" style={{ width: '50%' }} onClick={onNextTab}>Next Step</button>
          </div>
        </div>
      );
    case 'Personas':
      return (
        <div className="tab-content">
          <h2>Persona Overview</h2>
          {personas.length === 0 ? (
            <PersonaFilters onApplyFilters={(filters) => personaActions.generatePersonas(filters, setPersonas, personas, selectedTopic, setTopics, topics)} />
          ) : (
            <div className="research-container">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3>You have {personas.length} Persona{personas.length > 1 ? 's' : ''}</h3>
                <button
                  className="research-button"
                  onClick={() => setViewType(viewType === 'grid' ? 'list' : 'grid')}
                >
                  Switch to {viewType === 'grid' ? 'List View' : 'Grid View'}
                </button>
              </div>

              <div className={viewType === 'grid' ? "research-grid" : "research-list"}>
                {viewType === 'list' && (
                  <div className="list-header">
                    <div className="list-item">Photo</div>
                    <div className="list-item">Name</div>
                    <div className="list-item">Age</div>
                    <div className="list-item">Location</div>
                    <div className="list-item">Income</div>
                    <div className="list-item">Bio</div>
                    <div className="list-item">Actions</div>
                  </div>
                )}
                {personas.slice(0, visibleCount).map((persona) => (
                  <Persona
                    key={persona.id}
                    persona={persona}
                    onEdit={onPersonaEdit}
                    onDelete={onPersonaDelete}
                    viewType={viewType}
                  />
                ))}
              </div>

              {personas.length > 8 && visibleCount < personas.length && (
                <button
                  className="research-button"
                  onClick={() => personaActions.showMorePersonas(visibleCount, setVisibleCount)}
                >
                  Show More
                </button>
              )}

              <div className="button-container">
                <button className="research-button" onClick={onAddPersona}>
                  Add Persona
                </button>
                <button className="research-button" onClick={onNextTab}>
                  Next Step
                </button>
              </div>
            </div>
          )}
        </div>
      );
    case 'Responses':
      return (
        <div className="research-container">
          <h3>Responses Overview</h3>
          <ResponsesView
            selectedTopic={selectedTopic}
            personas={personas}
          />
          <button className="research-button" onClick={() => alert('Please upgrade your plan for premium features')}>
            Analyse Responses with AI
          </button>
        </div>
      );
    default:
      return null;
  }
};

export default TabContent;