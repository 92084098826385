import axios from 'axios';
import Anthropic from '@anthropic-ai/sdk';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const researchApi = {
  createResearch: async (mainQuestion, description, type) => {
    try {
      const response = await api.post('/research', {
        mainQuestion,
        description,
        type
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Failed to create research');
    }
  },

  generateResearchName: async (inputName) => {
    // If no API client, return original name
      return inputName; // Fallback to original name if API fails
  }
};