import React, { useState, useRef } from 'react';
import { useAuth } from '../authentication/AuthContext';
import '../styles/HomePage.css';
import '../index.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../i18n';
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";
import AnimatedHero from '../components/AnimatedHero';
import 'swiper/css/autoplay';
import { Navigation, Pagination, Autoplay } from 'swiper';

function HomePage() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, []);

  const { user } = useAuth();
  const [isPaused, setIsPaused] = useState(false);
  const swiperRef = useRef(null);

  const toggleAutoplay = () => {
    if (isPaused) {
      swiperRef.current.swiper.autoplay.start();
    } else {
      swiperRef.current.swiper.autoplay.stop();
    }
    setIsPaused(!isPaused);
  };

  return (
    <div className="home-page">
      <div className="top-hero hero hero-1">
        {/* <div className="hero-grid-item">
        <AnimatedHero />
        </div> */}
          <div className="hero-grid-item">
            <h1>{t("HomePage.heroTitle")}</h1>
          </div>
          {user ? (
            <a href="/research" className="standout-button button">{t("HomePage.startResearch")}</a>
          ) : (
            <a href="/login" className="standout-button button" onClick={() => { alert(t("HomePage.loginPrompt")); return false; }}>
              {t("HomePage.startResearch")}
            </a>
          )}
        </div>

        <div className="hero hero-1">
          <h2>How Does the Instant Research Service Work?</h2>
          <div>
            <Swiper
              ref={swiperRef}
              modules={[Pagination, Navigation, Autoplay]}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false
              }}
              speed={1500}
              loop={true}
              pagination={{
                clickable: true,
              }}
              navigation={true}
            >
              <SwiperSlide>
                <div className="slide-content content-grid-2">
                  <div className="card">
                    <img src={require('../components/promotions/ASC.png')} alt="Services Promotion" className="image-content" />
                    <h3 className='card-content'>Automated Survey Creation</h3>
                  </div>
                  <div className="textboxcontainer">
                    <h3>1. Create Your Research Topic:</h3>
                    <p>Start by defining what you want to research - this could be a product, service, or concept you want to understand better.</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide-content content-grid-2">
                  <div className="card">
                    <img src={require('../components/promotions/ASC.png')} alt="Services Promotion" className="image-content" />
                    <h3 className='card-content'>Automated Survey Creation</h3>
                  </div>
                  <div className="textboxcontainer">
                    <h3>2. Design Your Survey:</h3>
                    <p>Create your research questions with our flexible question builder. Choose from open-ended, multiple choice, or true/false questions to gather the insights you need.</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide-content content-grid-2">
                  <div className="card">
                    <img src={require('../components/promotions/MR.png')} alt="Services Promotion" className="image-content" />
                    <h3 className='card-content'>Market Research</h3>
                  </div>
                  <div className="textboxcontainer">
                    <h3>3. Define Your Target Audience:</h3>
                    <p>Use our demographic filters to specify your ideal respondents - including age range, location, and income level. Our AI will generate detailed personas matching your criteria.</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide-content content-grid-2">
                  <div className="card">
                    <img src={require('../components/promotions/MR.png')} alt="Services Promotion" className="image-content" />
                    <h3 className='card-content'>Market Research</h3>
                  </div>
                  <div className="textboxcontainer">
                    <h3>4. Get AI-Generated Responses:</h3>
                    <p>Our AI simulates responses from your created personas, providing realistic feedback based on their demographic profiles and characteristics.</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide-content content-grid-2">
                  <div className="card">
                    <img src={require('../components/promotions/DAR.png')} alt="Services Promotion" className="image-content" />
                    <h3 className='card-content'>Detailed Analysis and Reporting</h3>
                  </div>
                  <div className="textboxcontainer">
                    <h3>5. Analyze Results:</h3>
                    <p>Review responses across all your personas and use our AI analysis tools to uncover key insights and patterns in your research data.</p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <button className="button" onClick={toggleAutoplay}>
              {isPaused ? '⏵' : '⏸'}
            </button>
          </div>
        </div>

        <div className="hero hero-3">
          <h2>Meet our personas</h2>
            <Swiper
              modules={[Pagination, Navigation, Autoplay]}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false
              }}
              speed={1500}
              initialSlide={2}
              spaceBetween={30}
              slidesPerGroup={1}
              loop={true}
              pagination={{
                clickable: true,
              }}
              navigation={false}
              breakpoints={{
                520: { slidesPerView: 1 },
                600: { slidesPerView: 2 },
                1100: { slidesPerView: 3 },
                1600: { slidesPerView: 4 }
              }}
            >      
              <SwiperSlide>
                <div className="slide-content content-grid-2 container" style={{ marginBottom: "3rem" }}>
                    <div className="image-content">
                      <div className="card-image">
                        <img src='/InstantFlowsicon_wb.png' alt="" className="img" />
                      </div>
                      <h3 className="name">Leyla</h3>
                    </div>
                    <div>
                      <p className="description">Medical assistant 29 years old€ 38 584 per year Unmarried | No children</p>
                    </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="slide-content content-grid-2 container">
                    <div className="image-content">
                      <div className="card-image">
                        <img src='/InstantFlowsicon_wb.png' alt="" className="img" />
                      </div>
                      <h3 className="name">Mark</h3>
                    </div>
                    <div>
                      <p className="description">{t("HomePage.personas.description")}</p>
                    </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="slide-content content-grid-2 container" >
                    <div className="image-content">
                      <div className="card-image">
                        <img src='/InstantFlowsicon_wb.png' alt="" className="img" />
                      </div>
                      <h3 className="name">Leyla</h3>
                    </div>
                    <div>
                      <p className="description">{t("HomePage.personas.description")}</p>
                    </div>
                </div>
              </SwiperSlide>
            </Swiper>
        </div>

              <div className="hero hero-3" >
                <div className="hero-grid-item">
                  <img src={require('../components/logos/InstantFlowsLogo-1.png')} className='hero-image'/>
                </div>
                  <h3 style={{ color: 'var(--apple-blue)' }}>research made simple</h3>
              </div>
      </div>
  );
}

export default HomePage;
