import { QuestionType } from './ResearchConstants';
import { researchApi } from '../services/researchApi';

export const STORAGE_KEY = 'researchData';

export const saveToStorage = (topics) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(topics));
};

export const loadFromStorage = () => {
  const data = localStorage.getItem(STORAGE_KEY);
  return data ? JSON.parse(data) : [];
};

let counter = 1;
export const generateCounter = () => {
  return counter++;
};

export const generateUniqueId = () => {
  return Math.floor(Math.random() * 90000000) + 10000000;
};

export const validateFilters = (filters) => {
  return {
    ageRange: {
      min: Math.max(18, Math.min(filters.ageRange.min, 100)),
      max: Math.max(18, Math.min(filters.ageRange.max, 100))
    },
    region: filters.region || 'All',
    incomeRange: {
      min: Math.max(0, Math.min(filters.incomeRange.min, 1000000)),
      max: Math.max(0, Math.min(filters.incomeRange.max, 1000000))
    },
    count: Math.max(1, Math.min(filters.count, 1000))
  };
};

export const topicActions = {
  addTopic: async (name, setTopics, setNewTopicName, setSelectedTopic, setSelectedTab, isPremium) => {
    try {
      counter = 1; // Reset counter
      
      // Generate research name using Claude
      const generatedName = await researchApi.generateResearchName(name);

      const newTopic = {
        id: generateUniqueId(),
        name: generatedName,
        isPremium,
        questions: Array(5).fill(null).map(() => ({
          id: generateCounter(),
          text: `Question ${generateCounter()}`,
          type: Math.random() < 0.33 ? QuestionType.OPEN_ENDED : Math.random() < 0.66 ? QuestionType.MULTIPLE_CHOICE : QuestionType.TRUE_FALSE,
        })),
        personas: []
      };

      setTopics(prev => {
        const updatedTopics = [...prev, newTopic];
        saveToStorage(updatedTopics);
        return updatedTopics;
      });
      setNewTopicName('');
      setSelectedTopic(newTopic);
      setSelectedTab('Questions');
    } catch (error) {
      console.error('Failed to create topic:', error);
      // Still create the topic but with original name if API fails
      const newTopic = {
        id: generateUniqueId(),
        name: name,
        isPremium,
        questions: Array(5).fill(null).map(() => ({
          id: generateCounter(),
          text: `Question ${generateCounter()}`,
          type: Math.random() < 0.33 ? QuestionType.OPEN_ENDED : Math.random() < 0.66 ? QuestionType.MULTIPLE_CHOICE : QuestionType.TRUE_FALSE,
        })),
        personas: []
      };
      setTopics(prev => {
        const updatedTopics = [...prev, newTopic];
        saveToStorage(updatedTopics);
        return updatedTopics;
      });
      setNewTopicName('');
      setSelectedTopic(newTopic);
      setSelectedTab('Questions');
    }
  },

  newResearch: (setSelectedTopic, setSelectedTab) => {
    setSelectedTopic(null);
    setSelectedTab('Questions');
  },

  deleteTopic: (topicId, topics, setTopics, setSelectedTopic) => {
    const updatedTopics = topics.filter(t => t.id !== topicId);
    saveToStorage(updatedTopics);
    setTopics(updatedTopics);
    setSelectedTopic(null);
  },

  upgradeToPremium: (selectedTopic, topics, setTopics, setSelectedTopic) => {
    const updatedTopics = topics.map(t => 
      t.id === selectedTopic.id ? { ...t, isPremium: true } : t
    );
    
    // Save to storage
    saveToStorage(updatedTopics);
    
    // Update state
    setTopics(updatedTopics);
    setSelectedTopic({ ...selectedTopic, isPremium: true });
    
    return updatedTopics;
  }
};

export const questionActions = {
  addQuestion: (selectedTopic, setSelectedTopic, topics, setTopics) => {
    const newQuestion = {
      id: generateCounter(),
      text: 'New Question',
      type: QuestionType.OPEN_ENDED
    };
    setSelectedTopic(updatedTopic => {
      const newTopic = {
        ...updatedTopic,
        questions: [...updatedTopic.questions, newQuestion]
      };
      
      // Update topics and save
      const updatedTopics = topics.map(t => 
        t.id === selectedTopic.id ? newTopic : t
      );
      saveToStorage(updatedTopics);
      setTopics(updatedTopics);
      
      return newTopic;
    });
  },

  editQuestion: (questionId, updates, selectedTopic, setSelectedTopic, topics, setTopics) => {
    const updatedQuestions = selectedTopic.questions.map(q =>
      q.id === questionId ? { ...q, ...updates } : q
    );
    setSelectedTopic(updatedTopic => {
      const newTopic = {
        ...updatedTopic,
        questions: updatedQuestions
      };
      
      // Update topics and save
      const updatedTopics = topics.map(t =>
        t.id === selectedTopic.id ? newTopic : t
      );
      saveToStorage(updatedTopics);
      setTopics(updatedTopics);
      
      return newTopic;
    });
  },

  deleteQuestion: (questionId, selectedTopic, setSelectedTopic, topics, setTopics) => {
    setSelectedTopic(updatedTopic => {
      const newTopic = {
        ...updatedTopic,
        questions: updatedTopic.questions.filter(q => q.id !== questionId)
      };
      
      // Update topics and save
      const updatedTopics = topics.map(t =>
        t.id === selectedTopic.id ? newTopic : t
      );
      saveToStorage(updatedTopics);
      setTopics(updatedTopics);
      
      return newTopic;
    });
  }
};

export const personaActions = {
  addPersona: (personas, setPersonas, selectedTopic, setTopics, topics) => {
    const nextNumber = personas.length > 0 
      ? personas.reduce((max, persona) => Math.max(max, persona.number), 0) + 1 
      : 1;

    // Default values when no personas exist
    const defaultAge = 30;
    const defaultLocation = 'United States';
    const defaultIncome = 50000;

    // Create new persona with either existing data ranges or defaults
    const newPersona = {
      id: generateUniqueId(),
      number: nextNumber,
      name: `Persona ${nextNumber}`,
      age: personas.length > 0 
        ? Math.floor(Math.random() * (
            personas.reduce((max, persona) => Math.max(max, persona.age), defaultAge) - 
            personas.reduce((min, persona) => Math.min(min, persona.age), defaultAge)
          ) + personas.reduce((min, persona) => Math.min(min, persona.age), defaultAge))
        : defaultAge,
      location: personas.length > 0 
        ? personas[Math.floor(Math.random() * personas.length)].location 
        : defaultLocation,
      income: personas.length > 0 
        ? Math.floor(Math.random() * (
            personas.reduce((max, persona) => Math.max(max, persona.income), defaultIncome) - 
            personas.reduce((min, persona) => Math.min(min, persona.income), defaultIncome)
          ) + personas.reduce((min, persona) => Math.min(min, persona.income), defaultIncome))
        : defaultIncome,
      bio: 'Add bio'
    };

    setPersonas(updatedPersonas => {
      const newPersonas = [...updatedPersonas, newPersona];
      
      // Update topic's personas and save
      const updatedTopics = topics.map(t =>
        t.id === selectedTopic.id ? { ...t, personas: newPersonas } : t
      );
      saveToStorage(updatedTopics);
      setTopics(updatedTopics);
      
      return newPersonas;
    });
  },

  editPersona: (personaId, updates, personas, setPersonas, selectedTopic, setTopics, topics) => {
    setPersonas(updatedPersonas => {
      const newPersonas = updatedPersonas.map(p =>
        p.id === personaId ? { ...p, ...updates } : p
      );
      
      // Update topic's personas and save
      const updatedTopics = topics.map(t =>
        t.id === selectedTopic.id ? { ...t, personas: newPersonas } : t
      );
      saveToStorage(updatedTopics);
      setTopics(updatedTopics);
      
      return newPersonas;
    });
  },

  deletePersona: (personaId, personas, setPersonas, selectedTopic, setTopics, topics) => {
    setPersonas(updatedPersonas => {
      const newPersonas = updatedPersonas.filter(p => p.id !== personaId);
      
      // Update topic's personas and save
      const updatedTopics = topics.map(t =>
        t.id === selectedTopic.id ? { ...t, personas: newPersonas } : t
      );
      saveToStorage(updatedTopics);
      setTopics(updatedTopics);
      
      return newPersonas;
    });
  },

  generatePersonas: async (filters, setPersonas, personas, selectedTopic, setTopics, topics) => {
    counter=0
    // API call or generation logic here
    // For now, just create dummy personas
    const dummyPersonas = Array(filters.count).fill(null).map(() => {
      const numbervalue = generateCounter();
      return {
        id: generateUniqueId(),
        number: numbervalue,
        name: `Persona ${numbervalue}`,
        age: Math.floor(Math.random() * (filters.ageRange.max - filters.ageRange.min) + filters.ageRange.min),
        location: filters.region === 'Any' ? ['United States', 'Netherlands'][Math.floor(Math.random() * 2)] : filters.region,
        income: Math.round(Math.floor(Math.random() * (filters.incomeRange.max - filters.incomeRange.min) + filters.incomeRange.min) / 10000) * 10000, 
        bio: 'Generated bio'
      };
    });
    setPersonas(updatedPersonas => {
      const newPersonas = [...updatedPersonas, ...dummyPersonas];
      
      // Update topic's personas and save
      const updatedTopics = topics.map(t =>
        t.id === selectedTopic.id ? { ...t, personas: newPersonas } : t
      );
      saveToStorage(updatedTopics);
      setTopics(updatedTopics);
      
      return newPersonas;
    });
  },

  showMorePersonas: (currentVisible, setVisibleCount) => {
    setVisibleCount(Math.max(currentVisible + 2, 100));
  }
};

// Add new function to load data
export const loadResearchData = () => {
  return loadFromStorage();
};
