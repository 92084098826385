import { userPool } from '../authentication/AuthContext';  // Export userPool from AuthContext

export const api = {
  makeAuthenticatedRequest: async (endpoint, { method = 'GET', body = null } = {}) => {

    const user = userPool.getCurrentUser();
    if (!user) {
      console.error('No user found in userPool');
      throw new Error('No user logged in');
    }
  
  
    const session = await new Promise((resolve, reject) => {
      user.getSession((err, session) => {
        if (err) {
          console.error('Session error:', err);
          reject(err);
        }
        else resolve(session);
      });
    });
  
  
    const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${session.getIdToken().getJwtToken()}`
      },
      // credentials: 'include',
      mode: 'cors',
      body: body ? JSON.stringify(body) : null
    });
  
    if (!response.ok) {
      const text = await response.text();
      console.error('API Error:', {
        status: response.status,
        body: text
      });
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    return response.json();
  }
};