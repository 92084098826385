import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../authentication/AuthContext';
import '../styles/HomePage.css';
import '../index.css';
import { CognitoUser } from 'amazon-cognito-identity-js';

function LoginPage() {
  const [view, setView] = useState('login'); // 'login', 'register', or 'verify'
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const { login, signUp, verifyEmail } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');
    
    try {
      await login(username, password);
      const from = location.state?.from || '/';
      navigate(from, { replace: true });
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        setMessage('Please verify your email first.');
        setView('verify');
      } else {
        setError(err.message || 'Failed to login');
      }
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    setError('');
    
    try {
      await signUp(username, password, firstName, lastName);
      setMessage('Registration successful! Please check your email for verification code.');
      setView('verify');
    } catch (err) {
      if (err.code === 'UsernameExistsException') {
        try {
          await resendVerificationCode(username);
          setMessage('Account exists but not verified. A new verification code has been sent.');
          setView('verify');
        } catch (resendErr) {
          setError('Error resending verification code. Please try again.');
        }
      } else {
        setError(err.message || 'Failed to register');
      }
    }
  };

  const handleVerification = async (event) => {
    event.preventDefault();
    setError('');
    
    try {
      await verifyEmail(username, verificationCode);
      setMessage('Email verified successfully! You can now login.');
      setView('login');
    } catch (err) {
      setError(err.message || 'Failed to verify email');
    }
  };

  const handleResendCode = async () => {
    try {
      await resendVerificationCode(username);
      setMessage('Verification code resent! Please check your email.');
    } catch (err) {
      setError(err.message || 'Failed to resend code');
    }
  };

  const checkUserStatus = async (email) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool
      });

      cognitoUser.getStatus((err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      });
    });
  };

  const renderLogin = () => (
    <>
      <h1>Please Login:</h1>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="username">Email:</label>
          <input 
            type="email" 
            id="username" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            required 
          />
          <label htmlFor="password">Password:</label>
          <input 
            type="password" 
            id="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
        </div>
        <button type="submit" className="button standout-button">
          Login
        </button>
      </form>
      <p>
        Don't have an account?{' '}
        <button onClick={() => setView('register')} className="link-button">
          Register here
        </button>
      </p>
    </>
  );

  const renderRegister = () => (
    <>
      <h1>Create Account:</h1>
      <form onSubmit={handleRegister}>
        <div className="form-group">
          <label htmlFor="firstName">First Name:</label>
          <input 
            type="text" 
            id="firstName" 
            value={firstName} 
            onChange={(e) => setFirstName(e.target.value)} 
            required 
          />
          <label htmlFor="lastName">Last Name:</label>
          <input 
            type="text" 
            id="lastName" 
            value={lastName} 
            onChange={(e) => setLastName(e.target.value)} 
            required 
          />
          <label htmlFor="username">Email:</label>
          <input 
            type="email" 
            id="username" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            required 
          />
          <label htmlFor="password">Password:</label>
          <input 
            type="password" 
            id="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
        </div>
        <button type="submit" className="button standout-button">
          Register
        </button>
      </form>
      <p>
        Already have an account?{' '}
        <button onClick={() => setView('login')} className="link-button">
          Login here
        </button>
      </p>
    </>
  );

  const renderVerification = () => (
    <>
      <h1>Verify Email:</h1>
      <p>A verification code has been sent to: {username}</p>
      <form onSubmit={handleVerification}>
        <div className="form-group">
          <label htmlFor="code">Verification Code:</label>
          <input 
            type="text" 
            id="code" 
            value={verificationCode} 
            onChange={(e) => setVerificationCode(e.target.value)} 
            required 
          />
        </div>
        <div className="button-group">
          <button type="submit" className="button standout-button">
            Verify Email
          </button>
          <button 
            type="button" 
            onClick={handleResendCode} 
            className="button secondary-button"
          >
            Resend Code
          </button>
          <button 
            type="button" 
            onClick={() => setView('login')} 
            className="button link-button"
          >
            Back to Login
          </button>
        </div>
      </form>
    </>
  );

  return (
    <div className="login-page">
      <div className="hero hero-1 top-hero">
        <div className="container">
          {error && <div className="error-message">{error}</div>}
          {message && <div className="success-message">{message}</div>}
          
          {view === 'login' && renderLogin()}
          {view === 'register' && renderRegister()}
          {view === 'verify' && renderVerification()}
        </div>
      </div>
    </div>
  );
}

export default LoginPage;

