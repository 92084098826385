import React, { useState, useRef, useEffect } from 'react'; // Added useRef and useEffect
import { useAuth } from '../authentication/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import '../styles/Header.css';
import InstantFlowsLogo from './logos/InstantFlowsLogo-1.png';
import '../i18n';
import { useTranslation } from 'react-i18next';

function Header() {
  const { user, logout, credits, subscription } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const{t, i18n} = useTranslation() ;

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, []);

  const menuRef = useRef(null); // Create a ref for the menu
  const toggleRef = useRef(null); // Create a ref for the menu toggle button

  const handleLogout = () => {
    logout();
    navigate('/'); // Redirect to home on logout
  };

  // Close the menu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the menu and the toggle button
      if (
        menuRef.current && !menuRef.current.contains(event.target) &&
        toggleRef.current && !toggleRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    
    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef, toggleRef]);

  // Add blob animation effect
  useEffect(() => {
    const blob1 = document.querySelector('.blob-1');
    const blob2 = document.querySelector('.blob-2');
    let mouseX = 0;
    let mouseY = 0;
    let blob1X = 0;
    let blob1Y = 0;
    let blob2X = 0;
    let blob2Y = 0;

    const handleMouseMove = (e) => {
      mouseX = e.clientX;
      mouseY = e.clientY;
    };

    const animate = () => {
      // Calculate new positions with easing
      blob1X += (mouseX - blob1X) * 0.01;
      blob1Y += (mouseY - blob1Y) * 0.01;
      blob2X += (mouseX - blob2X) * 0.05;
      blob2Y += (mouseY - blob2Y) * 0.05;

      // Add gentle swaying motion using sine waves
      const time = Date.now() / 1000;
      const blob1Sway = Math.sin(time * 0.5) * 15; // Slower sway for blob1
      const blob2Sway = Math.sin(time * 0.7) * 20; // Faster sway for blob2

      // Apply new positions with sway
      if (blob1 && blob2) {
        blob1.style.transform = `translate(${blob1X - 150}px, ${blob1Y - 150 + blob1Sway}px)`;
        blob2.style.transform = `translate(${blob2X - 75}px, ${blob2Y - 75 + blob2Sway}px)`;
      }

      requestAnimationFrame(animate);
    };

    document.addEventListener('mousemove', handleMouseMove);
    animate();

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <header className="header">
      <div className="header-content">
        <Link to="/" className="logo-link">
          <img src={InstantFlowsLogo} alt={t('Header.aria.logo')} className="logo" />
        </Link>
        <button 
          ref={toggleRef} // Attach the ref to the button
          className={`menu-toggle ${isMenuOpen ? 'open' : ''}`}
          onClick={() => setIsMenuOpen(!isMenuOpen)} 
          aria-expanded={isMenuOpen} 
          aria-label={isMenuOpen ? t('Header.aria.closeMenu') : t('Header.aria.openMenu')}
        >
          <span className="menu-icon"></span>
        </button>

        <nav ref={menuRef} className={`overlay-nav ${isMenuOpen ? 'open' : ''}`}>
          <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
            <li><Link to="/about">{t('Header.menu.aboutUs')}</Link></li>
            <li><Link to="/ourpersonas">{t('Header.menu.personas')}</Link></li>
            <li><Link to="/pricing">{t('Header.menu.pricing')}</Link></li>
            <li><Link to="/blogs">{t('Header.menu.blogs')}</Link></li>         
            {user ? (
              <li className="account-menu">
                <div className="auth-buttons-container">
                  <Link to="/research" className="auth-button startresearch-button">
                    {t('Header.menu.startResearch')}
                  </Link>
                  <div className="account-trigger">
                    <Link to="/account" className="auth-button">
                      {t('Header.menu.myAccount')}
                    </Link>
                    <div className="account-dropdown">
                      <div className="account-info">
                        <p>Credits: {credits}</p>
                        <p>Plan: {subscription}</p>
                      </div>
                      <Link to="/payment" className="auth-button startresearch-button" state={{ type: 'credits' }}>
                        {t('Header.menu.topUpCredits')}
                      </Link>
                      <Link to="/login" className="auth-button startresearch-button" onClick={handleLogout}>
                        {t('Header.menu.logout')}
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            ) : (
              <li className="auth-buttons-container">
                <Link to="/login" className="auth-button login-button">
                  {t('Header.menu.login')}
                </Link>
                <Link to="/login" className="auth-button signup-button" 
                  onClick={() => { alert(t('Header.menu.loginPrompt'))}}>
                  {t('Header.menu.startResearch')}
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
      <div className="blob-container">
        <div className="blob blob-1"></div>
        <div className="blob blob-2"></div>
      </div>
    </header>
  );
}

export default Header;